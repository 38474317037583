import { FirebaseApp, initializeApp } from "firebase/app";
import { getAuth, User } from "firebase/auth";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  query,
} from "firebase/firestore";
import * as React from "react";
import { UserModel } from "../../models/user";
import Firebase from "../../services/firebase";
import config from "../../utils/config";
import appConfig from "../../utils/config";

interface AuthUserState {
  user: User | null;
  userDoc: UserModel | null;
  isConnecting: boolean;
  isConnected: boolean;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
}
type ContextState = {
  authUserState: AuthUserState;
  firebase: Firebase | null;
};

const initialState: AuthUserState = {
  user: null,
  userDoc: null,
  isConnecting: false,
  isConnected: false,
  isAuthenticating: true,
  isAuthenticated: false,
  isAdmin: false,
};

let firebase: Firebase;

const getFirebase = (app: FirebaseApp) =>
  firebase ?? (firebase = new Firebase(app));

export const FirebaseContext = React.createContext<ContextState>({
  authUserState: initialState,
  firebase: null,
});

type FirebaseContextProviderProps = {
  children: React.ReactNode;
};

const FirebaseContextProvider = ({
  children,
}: FirebaseContextProviderProps) => {
  const [authUserState, setAuthUserState] =
    React.useState<AuthUserState>(initialState);

  React.useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      setAuthUserState({
        ...authUserState,
        user,
        isAuthenticated: user != null,
        isAuthenticating: false,
        isConnecting: true,
      });
    });
    return unsubscribe;
  }, []);

  const app = initializeApp(config.firebaseConfig);
  const firebase = getFirebase(app);

  React.useEffect(() => {
    if (app) {
      // initializeAppCheck is dependent on the document object
      initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider(appConfig.recaptcha.siteKey),
        isTokenAutoRefreshEnabled: true,
      });
    }
  }, []);

  React.useEffect(() => {
    const { user } = authUserState;
    if (user && firebase) {
      const unsubscribe = onSnapshot(
        doc(firebase.db, "tenants", firebase.tenantId, "users", user.uid),
        async (doc) => {
          let isAdmin = false;
          try {
            const result = await user.getIdTokenResult();
            isAdmin = result.claims["admin"] ?? false;
          } catch (error) {
            console.log(error);
            setAuthUserState({ ...authUserState, isConnecting: false });
          }
          setAuthUserState({
            ...authUserState,
            isAdmin,
            userDoc: (doc.data() as UserModel) ?? null,
            isConnecting: false,
            isConnected: true,
          });
        }
      );
      return unsubscribe;
    }
  }, [authUserState.user, firebase]);

  return (
    <FirebaseContext.Provider value={{ authUserState, firebase }}>
      {children}
    </FirebaseContext.Provider>
  );
};

const useAuthUser = () => {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error("useAuthUser must be used within a FirebaseProvider");
  }
  return context.authUserState?.user;
};

const useAuthUserDoc = () => {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error("useAuthUser must be used within a FirebaseProvider");
  }
  return context.authUserState?.userDoc;
};

const useAuthIsAuthenticating = () => {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error(
      "useAuthIsAuthenticating must be used within a FirebaseProvider"
    );
  }
  return context.authUserState.isAuthenticating;
};

const useAuthIsAuthenticated = () => {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error(
      "useAuthIsAuthenticated must be used within a FirebaseProvider"
    );
  }
  return context.authUserState.isAuthenticated;
};

const useAuthIsConnected = () => {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error(
      "useAuthIsConnected must be used within a FirebaseProvider"
    );
  }
  return context.authUserState.isConnected;
};

const useAuthIsConnecting = () => {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error(
      "useAuthIsConnecting must be used within a FirebaseProvider"
    );
  }
  return context.authUserState.isConnecting;
};

const useAuthUserIsAdmin = () => {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error(
      "useAuthUserIsAdmin must be used within a FirebaseProvider"
    );
  }
  return context.authUserState.isAdmin;
};

const useFirebaseService = () => {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error("useAuthUser must be used within a FirebaseProvider");
  }
  return context.firebase;
};

export {
  FirebaseContextProvider,
  useAuthUser,
  useAuthUserDoc,
  useAuthIsAuthenticating,
  useAuthIsAuthenticated,
  useAuthIsConnecting,
  useAuthIsConnected,
  useAuthUserIsAdmin,
  useFirebaseService,
};
